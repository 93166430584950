import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import {
  NODE_AND_SUBLAYER_PADDING_SUM,
  NODE_VIEW_HEIGHT,
  NODE_VIEW_WIDTH,
} from '@constants/canvas/general';
import NodesRow from '@utils/canvas/NodesRow';

class NodeView {
  static readonly Height = NODE_VIEW_HEIGHT;

  static readonly Width = NODE_VIEW_WIDTH;

  private readonly id: string;

  private readonly node: Node;

  constructor(id: string) {
    this.id = id;
    this.node = useStore.getState().nodes.find((node) => node.id === this.id)!;
  }

  /*
      We divide the y position of the node by the row height to get the row index.
      Row index to node Y position mapping:

      Row 0: 0 - 87px
      Row 1: 88 - 175px
      Row 2: 176 - 263px
      ...
   */
  get rowIndex() {
    return NodeView.calculateRowByY(this.node.position.y);
  }

  get YPosition() {
    return NodeView.calculateYPosition(this.rowIndex);
  }

  /*
      Row 0: 0 * 88 + 24 = 24 (4px sublayer padding + 20px node padding)
      Row 1: 1 * 88 + 24 = 112 (4px sublayer padding + 20px node padding + 88px above node height)
      Row 2: 2 * 88 + 24 = 200 (4px sublayer padding + 20px node padding + 88px first row height + 88px second row height)
  */
  static calculateYPosition(rowIndex: number): number {
    return rowIndex * NodesRow.Height + NODE_AND_SUBLAYER_PADDING_SUM;
  }

  static calculateRowByY(y: number): number {
    return Math.floor(y / NodesRow.Height);
  }

  moveToRow(rowIndex: number): void {
    const y = NodeView.calculateYPosition(rowIndex);

    useStore.getState().updateNodePosition(this.id, { y });
  }
}

export default NodeView;
