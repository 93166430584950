import { useReactFlow } from 'reactflow';
import useFilteredCanvas from '@components/MainStage/hooks/useFilteredCanvas';
import {
  CANVAS_LEFT_MARGIN,
  CANVAS_WIDTH,
  FIT_VIEW_X_PADDING,
  MAX_ZOOM,
  MIN_ZOOM,
  NodesTypes,
} from '@constants/canvas/general';
import { subLayersNodeIdsByMode } from '@constants/canvas/layerNodes';
import { SubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import NodeView from '@utils/canvas/NodeView';
import SublayerView from '@utils/canvas/SublayerView';
import { getMaxX } from '@utils/canvasHelpers';

const useFitView = () => {
  const { setViewport } = useReactFlow();
  const { setZoom } = useCanvasZoom();
  const { nodes } = useFilteredCanvas();

  const {
    toolbox: { mode },
  } = useProject();

  return () => {
    const maxX = getMaxX(
      nodes.filter(
        (node) => node.type === NodesTypes.CustomNode && !node.hidden,
      ),
    );

    const width =
      CANVAS_LEFT_MARGIN + maxX + NodeView.Width + FIT_VIEW_X_PADDING;

    const height = mode
      ? subLayersNodeIdsByMode[mode].reduce((height, id) => {
          const subLayerVIew = new SublayerView(id as SubLayerTypes, nodes);

          if (subLayerVIew.hasVisibleNodes) {
            return (
              subLayerVIew.node.position.y + subLayerVIew.getElementHeight()
            );
          }

          return height;
        }, 0)
      : 0;

    const { y: topY } = subLayersNodeIdsByMode[mode!].reduce<{
      emptySubLayers: SubLayerTypes[];
      y: number;
    }>(
      (acc, id, currentIndex, array) => {
        const subLayerView = new SublayerView(id as SubLayerTypes, nodes);

        const isSubLayerIncluded =
          !currentIndex ||
          acc.emptySubLayers[currentIndex - 1] ===
            (array[currentIndex - 1] as SubLayerTypes);

        if (!subLayerView.hasVisibleNodes && isSubLayerIncluded) {
          return {
            emptySubLayers: [...acc.emptySubLayers, ...[id as SubLayerTypes]],
            y: subLayerView.node.position.y + subLayerView.getElementHeight(),
          };
        }

        return acc;
      },
      { emptySubLayers: [], y: 0 } as any,
    );

    const canvas = document.querySelector('#canvas');
    if (!canvas) return;

    const fitWidthZoomLevel =
      canvas.clientWidth / Math.min(width, CANVAS_WIDTH + CANVAS_LEFT_MARGIN);

    const fitHeightZoomLevel = canvas.clientHeight / (height - topY);

    const zoomLevel = Math.max(
      Math.min(Math.min(fitWidthZoomLevel, fitHeightZoomLevel), MAX_ZOOM),
      MIN_ZOOM,
    );

    setViewport({
      zoom: zoomLevel,
      x: CANVAS_LEFT_MARGIN * zoomLevel,
      y: -topY * zoomLevel,
    });

    setZoom(zoomLevel);
  };
};

export default useFitView;
