import { useState } from 'react';
import { Modes } from '@constants/canvas/layers';
import { ProjectToolBox, ProjectViews } from '@context/Project/types';

const useToolbox = () => {
  const [toolbox, setToolbox] = useState<ProjectToolBox>({
    mode: null,
    domains: [],
    view: ProjectViews.Model,
  });

  const changeToolbox = (newToolbox: Partial<ProjectToolBox>) => {
    setToolbox((prev) => ({ ...prev, ...newToolbox }));
  };

  const handleViewChange = (view: ProjectViews) => changeToolbox({ view });
  const handleModeChange = (mode: Modes | null) => changeToolbox({ mode });
  const handleDomainsChange = (domains: string[]) => changeToolbox({ domains });

  return {
    toolbox,
    handleViewChange,
    handleModeChange,
    handleDomainsChange,
  };
};

export default useToolbox;
