import { Node } from 'reactflow';
import { canvasState } from '@components/MainStage/store';
import {
  AllLayersListByMode,
  CANVAS_DEFAULT_HEIGHT,
  CANVAS_HEIGHT_PADDING,
} from '@constants/canvas/general';
import { subLayersNodesByMode } from '@constants/canvas/layerNodes';
import {
  LayerSubLayers,
  LayerTypes,
  ModeLayers,
  Modes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { setCanvasHeight } from '@store/slices/uiSlice';
import { store } from '@store/store';
import LayerView from '@utils/canvas/LayerView';
import NodesRow from '@utils/canvas/NodesRow';
import SublayerView from '@utils/canvas/SublayerView';

class CanvasModelRenderer {
  // This method builds the canvas layers and sublayers hierarchy from scratch.
  static async redrawFullCanvasLayers(mode: Modes) {
    const layerIds = ModeLayers[mode];

    let topLayerHeight = 0;

    layerIds.forEach((layerId) => {
      const layerView = new LayerView(layerId);

      const subLayers = LayerSubLayers[layerId];

      // All sublayers should be placed under the layer Title block.
      let topSubLayerHeight = LayerView.TopTitleBlockHeight;

      subLayers.forEach((subLayerId) => {
        const subLayerView = new SublayerView(subLayerId);
        subLayerView.fitNodes();
        subLayerView.setYPosition(topLayerHeight + topSubLayerHeight);

        topSubLayerHeight += subLayerView.getElementHeight();
      });

      layerView.fitSubLayers();
      layerView.setYPosition(topLayerHeight);

      topLayerHeight += layerView.getElementHeight();
    });

    CanvasModelRenderer.resetCanvasHeight(mode);
  }

  // This method adjusts the position of the layers and sublayers below the given layer.
  static async adjustBellowLayers(
    mode: Modes,
    startLayerId: SubLayerTypes | LayerTypes,
  ) {
    const startIndex = AllLayersListByMode[mode].indexOf(startLayerId);
    const mergedLayers = AllLayersListByMode[mode].slice(startIndex);

    let offset = 0;

    mergedLayers.forEach((id) => {
      const isLayer = (ModeLayers[mode] as readonly any[]).includes(id);

      if (isLayer) {
        // Layer logic
        const layerView = new LayerView(id as LayerTypes);
        layerView.shiftYPosition(offset);
      } else {
        // Sub Layer logic
        const subLayerView = new SublayerView(id as SubLayerTypes);

        subLayerView.shiftYPosition(offset);

        if (subLayerView.rowsCountHeightDifference !== 0) {
          const moveDownRowsCount = -subLayerView.rowsCountHeightDifference;

          offset += moveDownRowsCount * NodesRow.Height;

          subLayerView.fitNodes();
          new LayerView(subLayerView.parentLayerId).fitSubLayers();
        }
      }
    });

    CanvasModelRenderer.resetCanvasHeight(mode);
  }

  static removeEmptyRows(mode: Modes, filteredNodes: Node[]) {
    const layers = subLayersNodesByMode[mode];

    const visibleNodes = filteredNodes.filter((node) => !node.hidden);
    canvasState().setNodes(visibleNodes);

    layers.forEach((layerNode) => {
      new SublayerView(layerNode.id as SubLayerTypes).collapseRows();
    });

    this.redrawFullCanvasLayers(mode);
  }

  static async redrawAfterSubLayerChanges(
    mode: Modes,
    subLayerId: SubLayerTypes,
  ) {
    const subLayerView = new SublayerView(subLayerId);

    if (subLayerView.rowsCountHeightDifference !== 0) {
      CanvasModelRenderer.adjustBellowLayers(mode, subLayerId);
    }
  }

  static calculateCanvasHeight(mode: Modes | null) {
    if (!mode) return CANVAS_DEFAULT_HEIGHT;

    const layers = ModeLayers[mode] as readonly LayerTypes[];

    return layers.reduce((acc, id) => {
      try {
        return acc + new LayerView(id).getElementHeight();
      } catch (e) {
        return acc;
      }
    }, CANVAS_HEIGHT_PADDING);
  }

  static resetCanvasHeight(mode: Modes | null) {
    const height = CanvasModelRenderer.calculateCanvasHeight(mode);
    store.dispatch(setCanvasHeight(height));
  }
}

export default CanvasModelRenderer;
