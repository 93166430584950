import { useMemo, useState } from 'react';
import {
  EntityReviewStatus,
  EntityValidationStatus,
  ReadableEntityReviewStatus,
  ReadableEntityValidationStatus,
} from '@constants/canvas/layers';

const validationStatusOptions = [
  EntityValidationStatus.Validated,
  EntityValidationStatus.Draft,
].map((status) => ({
  label: ReadableEntityValidationStatus[status],
  value: status,
}));

const reviewStatusOptions = [EntityReviewStatus.ReviewRequired].map(
  (status) => ({
    label: ReadableEntityReviewStatus[status],
    value: status,
  }),
);

export const useFilters = () => {
  const [validationStatus, setValidationStatus] = useState([]);
  const [reviewStatus, setReviewStatus] = useState([]);

  const validationStatusSelectedFilters = useMemo(
    () =>
      validationStatus.map((status) => ({
        label: status,
      })),
    [validationStatus],
  );

  const reviewStatusesSelectedFilters = useMemo(
    () =>
      reviewStatus.map((status) => ({
        label: status,
      })),
    [reviewStatus],
  );

  const selectedFilters = useMemo(
    () => [
      ...validationStatusSelectedFilters.map((status) => status.label),
      ...reviewStatusesSelectedFilters.map((status) => status.label),
    ],
    [validationStatusSelectedFilters, reviewStatusesSelectedFilters],
  );

  const handleClearAllFilters = () => {
    setValidationStatus([]);
    setReviewStatus([]);
  };

  return {
    validationStatusOptions,
    reviewStatusOptions,
    validationStatus,
    reviewStatus,
    setValidationStatus,
    setReviewStatus,
    selectedFilters,
    handleClearAllFilters,
  };
};
