import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
} from 'react';
import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { MenuState } from '@components/MainStage/types';
import {
  NODE_MENU_MARGIN,
  NODE_MENU_Y_HELP_OFFSET,
  NodesTypes,
} from '@constants/canvas/general';
import { subLayerParent, SubLayerTypes } from '@constants/canvas/layers';
import { EditNodeDrawerMap } from '@constants/entities/drawers';
import { ReactFlowId } from '@constants/entities/reports';
import { useProject } from '@context/Project';
import useAssets from '@hooks/useAssets';
import { isLayerDisabled } from '@utils/canvasHelpers';
import { isElementLocal } from '@utils/drawers';

const useNodeHandlers = ({
  menu,
  closeMenu,
  openMenu,
  setMenu,
  onPaneClick,
}: {
  menu: MenuState | null;
  closeMenu: () => void;
  openMenu: () => void;
  setMenu: Dispatch<SetStateAction<MenuState | null>>;
  onPaneClick: () => void;
}) => {
  const { selectNodeAndRemoveLocal } = useStore();

  const {
    setDrawer,
    toolbox: { mode },
  } = useProject();

  const { selectedAssetId } = useAssets();

  const onMove = useCallback(() => {
    if (menu) closeMenu();
  }, [menu]);

  const onNodeClick = useCallback(
    (_: MouseEvent, newNode: Node) => {
      if (newNode?.type !== NodesTypes.CustomNode) {
        onPaneClick();
        return;
      }

      if (menu) closeMenu();

      const layerId = subLayerParent[newNode.parentNode as SubLayerTypes];
      selectNodeAndRemoveLocal(newNode.id);
      setDrawer({ type: EditNodeDrawerMap[layerId] });
    },
    [onPaneClick],
  );

  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: Node) => {
      event.preventDefault();
      if (isElementLocal(node.id)) return;

      const { type = '', id } = node;
      const pane = document
        .querySelector(`#${ReactFlowId}`)
        ?.getBoundingClientRect();

      if (type === NodesTypes.CustomNode) {
        if (pane) {
          const nodeDOM = document.querySelector<HTMLElement>(
            `[data-id="${id}"]`,
          );

          if (nodeDOM) {
            const nodeRect = nodeDOM.getBoundingClientRect();
            const top =
              nodeRect.y - NODE_MENU_Y_HELP_OFFSET + nodeRect.height / 2;
            const left = nodeRect.right + NODE_MENU_MARGIN;

            setMenu({
              id: node.id,
              type: 'node',
              top,
              left,
              position: 'fixed',
            });

            openMenu();
          }
        }
      } else if (type === NodesTypes.SubLayerNode && mode) {
        const isMenuDisabled = isLayerDisabled(id, mode, selectedAssetId);

        if (pane && !isMenuDisabled) {
          setMenu({
            id: node.id,
            type: 'subLayer',
            top: event.clientY - pane.top,
            left: event.clientX - pane.left,
          });
          openMenu();
        }
      }
    },
    [mode, selectedAssetId],
  );

  return { onNodeClick, onMove, onNodeContextMenu };
};

export default useNodeHandlers;
