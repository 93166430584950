import { Dispatch, MouseEvent, SetStateAction, useCallback } from 'react';
import { Connection, Edge } from 'reactflow';
import useStore from '@components/MainStage/store';
import { MenuState } from '@components/MainStage/types';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ReactFlowId } from '@constants/entities/reports';
import { useProject } from '@context/Project';
import NodesRow from '@utils/canvas/NodesRow';
import { isElementLocal } from '@utils/drawers';

const useEdgeHandlers = ({
  menu,
  closeMenu,
  openMenu,
  setMenu,
}: {
  menu: MenuState | null;
  closeMenu: () => void;
  openMenu: () => void;
  setMenu: Dispatch<SetStateAction<MenuState | null>>;
}) => {
  const { setDrawer } = useProject();
  const { clearLocalEdges, clearLocalNodes, onConnect, selectEdge } =
    useStore();

  const clearLocalEntities = useCallback(() => {
    clearLocalEdges();
    clearLocalNodes();
  }, []);

  const onEdgeAdded = useCallback((connection: Connection) => {
    clearLocalEntities();

    const createdEdgeId = onConnect(connection);

    if (createdEdgeId) {
      selectEdge(createdEdgeId);
      setDrawer({ type: ProjectDrawerTypes.AddRelationship });
    }
  }, []);

  const onEdgeClick = useCallback(
    (_: MouseEvent, edge: Edge) => {
      if (menu) closeMenu();
      clearLocalEntities();
      selectEdge(edge.id);
      setDrawer({ type: ProjectDrawerTypes.EditRelationship });
    },
    [menu],
  );

  const onEdgeContextMenu = useCallback((event: MouseEvent, edge: Edge) => {
    event.preventDefault();
    if (isElementLocal(edge.id)) return;

    const pane = document
      .querySelector(`#${ReactFlowId}`)
      ?.getBoundingClientRect();

    selectEdge(edge.id);

    if (pane) {
      setMenu({
        id: edge.id,
        type: 'edge',
        top: event.clientY - pane.top - NodesRow.Height / 2,
        left: event.clientX - pane.left,
      });

      openMenu();
    }
  }, []);

  return { onEdgeAdded, onEdgeClick, onEdgeContextMenu };
};

export default useEdgeHandlers;
