import { MouseEvent, useCallback } from 'react';
import { Edge } from 'reactflow';
import useStore from '@components/MainStage/store';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { useProject } from '@context/Project';
import { Tabs } from '@views/Insights/types';

const useEdgeClick = (onPaneClick: () => void) => {
  const {
    handleCloseDrawer,
    setDrawer,
    insightsTab: type,
    isVersionsShown,
  } = useProject();
  const { selectEdge } = useStore();

  return useCallback(
    async (_: MouseEvent, edge: Edge) => {
      if (!isVersionsShown && type === Tabs.Default) {
        onPaneClick();
        return;
      }

      await handleCloseDrawer();

      selectEdge(edge.id);
      setDrawer({ type: ProjectDrawerTypes.EditRelationship });
    },
    [type, isVersionsShown, handleCloseDrawer],
  );
};

export default useEdgeClick;
