import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { SUB_LAYER_PADDING_Y } from '@constants/canvas/general';
import {
  LayerTypes,
  subLayerParent,
  SubLayerTypes,
} from '@constants/canvas/layers';
import LayerViewBase from '@utils/canvas/LayerViewBase';
import NodesRow from '@utils/canvas/NodesRow';
import NodeView from '@utils/canvas/NodeView';

class SublayerView extends LayerViewBase<SubLayerTypes> {
  static readonly PaddingY: number = SUB_LAYER_PADDING_Y;

  static readonly FullPaddingY: number = SublayerView.PaddingY * 2;

  static readonly MinRowsCount: number = 1;

  static readonly InitialHeight: number =
    NodesRow.Height + SublayerView.FullPaddingY;

  public readonly nodes: Node[];

  /*
    Rows matrix is an array or arrays
    that representing rows in sublayer and contains nodes.
 */
  public readonly rowsMatrix: Node[][];

  constructor(id: SubLayerTypes, nodes?: Node[]) {
    super(id);

    this.nodes = (nodes ?? useStore.getState().nodes).filter(
      (node) => node.parentNode === this.id,
    );

    this.rowsMatrix = this.nodes.reduce((nodeRows, node) => {
      const nodeView = new NodeView(node.id);

      nodeRows[nodeView.rowIndex] = [
        ...(nodeRows[nodeView.rowIndex] ?? []),
        ...[node],
      ];

      return nodeRows;
    }, [] as Node[][]);
  }

  get hasVisibleNodes(): boolean {
    return this.nodes.some((node) => !node.hidden);
  }

  /*
      Return rows count based on nodes in store
      Sublayer should have at least one row. Even if there are no nodes.
   */
  get rowsCount(): number {
    return this.rowsMatrix.length || SublayerView.MinRowsCount;
  }

  /*
      Return rows count based on sublayer height
   */
  get rowsCountFit(): number {
    return Math.ceil(
      (this.getElementHeight() - SublayerView.FullPaddingY) / NodesRow.Height,
    );
  }

  /*
     This parameter shows a difference between:

     rows count counted from sublayer DOM element height
     AND
     rows count based on nodes Y positions

     For example DOM sublayer element could have height 272px (it means 3 rows),
     BUT in our store we have nodes that placed in 2 rows only,
     so in UI we see extra empty row at bottom
   */
  get rowsCountHeightDifference(): number {
    return this.rowsCountFit - this.rowsCount;
  }

  get parentLayerId(): LayerTypes {
    return subLayerParent[this.id];
  }

  /*
      Nodes could be placed in different rows inside sublayer.
      BUT sublayer DOM element has default height 96px, so we should calculate it based on nodes count.
   */
  fitNodes(): void {
    this.setHeight(
      this.rowsCount * NodesRow.Height + SublayerView.FullPaddingY,
    );
  }

  /*
      We need to collapse rows to remove empty rows.
      We should move nodes to the top of the sublayer, so there are no empty rows.
   */
  collapseRows(): void {
    const filledRows = this.rowsMatrix.filter((row) => row.length);

    filledRows.forEach((rowToMove, rowIndex) => {
      rowToMove.forEach((nodeToMove) => {
        new NodeView(nodeToMove.id).moveToRow(rowIndex);
      });
    });
  }
}

export default SublayerView;
