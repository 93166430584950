import React, { useMemo } from 'react';
import { BezierEdge, EdgeProps } from 'reactflow';
import { useNodesDataContext } from '@context/NodesDataContext';
import { themePalette } from '@theme/muiTheme';

export function CustomEdge({ selected, data, ...props }: EdgeProps) {
  const { isEdgeDisabled } = useNodesDataContext();
  const { child_id, parent_id } = data.dto;

  const isDisabled = isEdgeDisabled({ child_id, parent_id });

  const strokeColor = useMemo(() => {
    if (selected) {
      return themePalette.grey[1000];
    }

    if (isDisabled) {
      return themePalette.grey[600];
    }

    return themePalette.grey[700];
  }, [isDisabled, selected]);

  return (
    <BezierEdge
      {...props}
      selected
      style={{ stroke: strokeColor, strokeWidth: 2 }}
    />
  );
}
