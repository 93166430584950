import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
  XYPosition,
} from 'reactflow';
import { EntityData } from '@components/MainStage/types';
import { NodesTypes } from '@constants/canvas/general';
import { EntityValidationStatus } from '@constants/canvas/layers';
import { DependencyTypes, WeightTypes } from '@constants/entities/relationship';
import { nanoid } from '@reduxjs/toolkit';
import { EdgeDTO } from '@store/services/nodes/types';
import { findMaxCoordinates } from '@utils/helpers';
import { createWithEqualityFn } from 'zustand/traditional';

export type RFState = {
  nodes: Node[];
  edges: Edge[];
  getNodes: () => Node[];
  setNodes: (nodes: Node<any, string | undefined>[]) => void;
  setEdges: (edges: Edge[]) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: (connection: Connection) => false | string;
  addNode: (node: Node) => void;
  getActiveNode: () => Node | undefined;
  getNodeById: (id: string) => Node | undefined;
  getActiveEdge: () => Edge<EntityData<EdgeDTO>> | undefined;
  setActiveNode: (nodeId: string) => void;
  selectNodeAndRemoveLocal: (nodeId: string) => void;
  selectNode: (nodeId: string) => void;
  selectEdge: (edgeId: string) => void;
  unselectActiveNode: () => void;
  unselectActiveEdge: () => void;
  clearLocalNodes: () => void;
  clearLocalEdges: () => void;
  deleteNode: (nodeId: string) => void;
  updateNodeLabel: (nodeId: string, label: string) => void;
  updateNodePosition: (nodeId: string, position: object) => void;
  updateNode: (nodeId: string, data: object) => void;
  addChildNode: (
    parentNodeId: string,
    position: XYPosition,
    selected?: boolean,
  ) => string;
  getIsNodeTargetConnected: (nodeId: string) => boolean;
  getIsNodeSourceConnected: (nodeId: string) => boolean;
  getLastNodeCoordinates: () => { x: number; y: number };
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = createWithEqualityFn<RFState>((set, get) => {
  return {
    nodes: [],
    edges: [],
    getNodes: () => get().nodes,
    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },
    onConnect: (connection: Connection) => {
      const existConnection = get().edges.find(
        (edge) =>
          edge.source === connection.source &&
          edge.target === connection.target,
      );

      if (existConnection) return false;

      const id = `local-${nanoid(10)}`;

      set({
        edges: addEdge(
          {
            id,
            selected: true,
            animated: true,
            ...connection,
            data: {
              dto: {
                dependency: DependencyTypes.AND,
                weight: 0,
                weight_type: WeightTypes.Auto,
                parent_id: connection.source,
                child_id: connection.target,
              },
              canvas: {},
            },
          },
          get().edges,
        ),
      });

      set({
        nodes: get().nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              selectedWithEdge:
                node.id === connection.source || node.id === connection.target,
            },
          },
        })),
      });

      return id;
    },
    updateNodeData(id: string, data: object) {
      set({
        nodes: get().nodes.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, ...data } } : node,
        ),
      });
    },
    updateNodePosition(id: string, position: object) {
      set({
        nodes: get().nodes.map((node) =>
          node.id === id
            ? { ...node, position: { ...node.position, ...position } }
            : node,
        ),
      });
    },
    updateNode(id: string, data: object) {
      set({
        nodes: get().nodes.map((node) =>
          node.id === id ? { ...node, ...data } : node,
        ),
      });
    },
    getActiveNode: () => {
      return get().nodes.find((node: Node) => node.selected);
    },
    getNodeById: (id: string) => {
      return get().nodes.find((node: Node) => node.id === id);
    },
    getActiveEdge: () => {
      return get().edges.find((edge: Edge) => edge.selected);
    },
    setActiveNode: (nodeId: string) => {
      set({
        nodes: get().nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        })),
      });
    },

    selectNodeAndRemoveLocal: (nodeId: string) => {
      const { nodes, edges } = get();

      set({
        nodes: nodes.reduce<Node[]>((newNodes, node) => {
          if (node.id.startsWith('local')) {
            return newNodes;
          }

          newNodes.push({
            ...node,
            selected: node.id === nodeId,
          });

          return newNodes;
        }, []),

        edges: edges.reduce<Edge[]>((newEdges, edge) => {
          if (edge.id.startsWith('local')) {
            return newEdges;
          }

          newEdges.push({
            ...edge,
            selected: false,
          });

          return newEdges;
        }, []),
      });
    },

    addChildNode: (
      parentNodeId: string,
      position: XYPosition,
      selected = true, // when user add a child node, it should become active
    ) => {
      const id = `local-${nanoid()}`;

      const newNode: Node = {
        id,
        type: NodesTypes.NewNode,
        data: {
          dto: {
            domain_id: null,
            valid_status: EntityValidationStatus.Draft,
            review_required: false,
          },
          canvas: {},
        },
        position,
        parentNode: parentNodeId,
        extent: 'parent',
        selected,
      };

      set({
        nodes: [...get().nodes, newNode],
      });

      return id;
    },
    clearLocalNodes: () => {
      set({
        nodes: get().nodes.filter((node) => !node.id.startsWith('local')),
      });
    },
    clearLocalEdges: () => {
      set({
        edges: get().edges.filter((edge) => !edge.id.startsWith('local')),
      });
    },
    addNode: (newNode: Node) => {
      set({
        nodes: [...get().nodes, newNode],
      });
    },
    deleteNode: (id: string) => {
      set({
        nodes: get().nodes.filter((node) => node.id !== id),
        edges: get().edges.filter((edge) => edge.source !== id),
      });
    },

    selectNode: (nodeId: string) => {
      set({
        nodes: get().nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        })),
      });
    },

    selectEdge: (edgeId: string) => {
      const selectedEdge = get().edges.find((edge) => edge.id === edgeId);
      const sourceNode = get().nodes.find(
        (node) => node.id === selectedEdge?.source,
      );
      const targetNode = get().nodes.find(
        (node) => node.id === selectedEdge?.target,
      );

      set({
        nodes: get().nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              selectedWithEdge:
                node.id === targetNode?.id || node.id === sourceNode?.id,
            },
          },
        })),
      });
      set({
        edges: get().edges.map((edge) => ({
          ...edge,
          selected: edge.id === edgeId,
          animated: edge.id === edgeId,
        })),
      });
    },

    unselectActiveNode() {
      set({
        nodes: get().nodes.map((node) => ({ ...node, selected: false })),
      });
    },

    unselectActiveEdge() {
      set({
        edges: get().edges.map((edge) => ({
          ...edge,
          selected: false,
          animated: false,
        })),
      });
      set({
        nodes: get().nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              selectedWithEdge: false,
            },
          },
        })),
      });
    },

    setNodes: (nodes: Node[]) => {
      set({
        nodes: [...nodes],
      });
    },
    setEdges: (edges: Edge[]) => {
      set({
        edges: [...edges],
      });
    },
    getIsNodeTargetConnected: (nodeId: string) => {
      const { edges } = get();

      return !!edges.find((edge) => edge.target === nodeId);
    },
    getIsNodeSourceConnected: (nodeId: string) => {
      const { edges } = get();

      return !!edges.find((edge) => edge.source === nodeId);
    },
    getLastNodeCoordinates: () => {
      const { nodes } = get();
      const customNodes = nodes.filter(
        (node) => node.type === NodesTypes.CustomNode,
      );

      return findMaxCoordinates(customNodes);
    },
    updateNodeLabel: (nodeId: string, label: string) => {
      set({
        nodes: get().nodes.map((node) => {
          if (node.id === nodeId) {
            // it's important to create a new object here, to inform React Flow about the changes
            node.data = { ...node.data, dto: { ...node.data.dto, label } };
          }

          return node;
        }),
      });
    },
  };
});

export const canvasState = useStore.getState;
export default useStore;
