import React from 'react';
import { NodeProps } from 'reactflow';
import { UI_SMALL_LAYERS } from '@constants/canvas/general';
import { LayerTypes } from '@constants/canvas/layers';
import useCanvasWidth from '@hooks/canvas/useCanvasWidth';

import { LayerNodeStyled, StyledText } from './LayerNode.styled';

export type NodeData = {
  label: string;
};

export function LayerNode({ id, data }: NodeProps<NodeData>) {
  const isSmall = UI_SMALL_LAYERS.includes(id as LayerTypes);
  const canvasWidth = useCanvasWidth();

  return (
    <LayerNodeStyled id={id} $small={isSmall} $canvasWidth={canvasWidth}>
      <StyledText variant="h3">{data.label}</StyledText>
    </LayerNodeStyled>
  );
}
