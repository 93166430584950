import React, { FC, memo, MouseEvent } from 'react';
import { DeleteNodeModal } from '@components/MainStage/ContextMenu/NodeContextMenu/DeleteNodeModal';
import useIsNodeAddedToAsset from '@components/MainStage/hooks/useIsNodeAddedToAsset';
import useStore from '@components/MainStage/store';
import { LayerTypes, SubLayerTypes } from '@constants/canvas/layers';
import {
  AddNodeDrawerMap,
  EditNodeDrawerMap,
} from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useCenterNodeViewport from '@hooks/canvas/useCenterNodeViewport';
import useCanEditModel from '@hooks/useCanEditModel';
import useNode from '@hooks/useNode';
import useToggle from '@hooks/useToggle';
import {
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { isElementLocal } from '@utils/drawers';
import { CloseIcon, EntityIcon, MoreIcon, TrashIcon } from '@utils/iconsDefs';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

type Props = {
  layerId: LayerTypes;
  subLayerId: SubLayerTypes;
  id: string;

  title: string;
};

const NodeItem: FC<Props> = ({ layerId, id, subLayerId, title }) => {
  const { selectNode, getActiveNode } = useStore();
  const { setDrawer, handleCloseDrawer } = useProject();
  const [isDeleteOpen, { toggle, off }] = useToggle();
  const showMoreButton = useCanEditModel();

  const isNodeActive = id === getActiveNode()?.id;
  const isNodeAdded = useIsNodeAddedToAsset(id);
  const node = useNode(id);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  });

  const centerNode = useCenterNodeViewport();

  const handleOpenDrawer = async () => {
    await handleCloseDrawer();
    selectNode(id);

    const drawersMap = isElementLocal(id)
      ? AddNodeDrawerMap
      : EditNodeDrawerMap;

    setDrawer({ type: drawersMap[layerId] });

    if (node) {
      centerNode(node.position, subLayerId);
    }
  };

  const { onClick, ...triggerProps } = bindTrigger(popupState);

  const handleMoreClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick(e);
  };

  const handleDelete = async () => {
    popupState.close();
    await handleCloseDrawer();

    toggle();
  };

  return (
    <>
      <DeleteNodeModal open={isDeleteOpen} onClose={off} nodeId={id} />

      <MenuItem
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
          '&:hover .more': { opacity: 1 },
        }}
        disableRipple
        onClick={handleOpenDrawer}
      >
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          sx={{ width: '100%', overflow: 'hidden' }}
        >
          <EntityIcon
            width={20}
            height={20}
            fill={themePalette.grey[isNodeActive ? 1000 : 800]}
          />

          <Typography
            variant="body1"
            color={themePalette.grey[isNodeActive ? 1000 : 800]}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Stack>
        {showMoreButton && (
          <IconButton
            className="more"
            sx={{
              opacity: popupState.isOpen ? 1 : 0,
              borderRadius: '4px',
              borderColor: popupState.isOpen
                ? `${themePalette.grey[900]} !important`
                : themePalette.grey[700],

              '&:hover': {
                borderColor: `${themePalette.grey[900]} !important`,

                '& .icon': {
                  fill: `${themePalette.grey[1000]} !important`,
                },
              },
            }}
            size="small"
            variant="simple"
            {...triggerProps}
            onClick={handleMoreClick}
          >
            <MoreIcon
              width={16}
              height={16}
              fill={
                popupState.isOpen
                  ? themePalette.grey[1000]
                  : themePalette.grey[800]
              }
              className="icon"
            />
          </IconButton>
        )}
      </MenuItem>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{ paper: { sx: { width: 220, mt: 4 } } }}
      >
        {isNodeAdded ? (
          <MenuItem
            sx={{ color: themePalette.grey[1000] }}
            onClick={handleDelete}
          >
            <CloseIcon fill="currentColor" />
            Remove entity
          </MenuItem>
        ) : (
          <MenuItem
            sx={{
              color: themePalette.system.error,
              '&:hover': { color: themePalette.system.error },
            }}
            onClick={handleDelete}
          >
            <TrashIcon />
            Delete
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

export default memo(NodeItem);
