import {
  createContext,
  FC,
  lazy,
  ReactNode,
  Suspense,
  useContext,
  useState,
} from 'react';
import { SnackbarOrigin } from '@mui/material';

const CustomToast = lazy(() =>
  import('@components/CustomToast').then((module) => ({
    default: module.CustomToast,
  })),
);

type ShowToastOptions = {
  containerId?: string;
  anchorOrigin?: SnackbarOrigin;
  width?: string | number;
  header?: string;
  verticalCenter?: boolean;
};

interface ToastContextProps {
  showToast: (
    message: FC | string,
    type: 'success' | 'error' | 'warning' | 'info',
    options?: ShowToastOptions,
  ) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastProps {
  message: FC | string;
  severity: 'success' | 'error' | 'warning' | 'info';
  containerId?: string;
  anchorOrigin?: SnackbarOrigin;
  width?: string | number;
  header?: string;
  verticalCenter?: boolean;
}

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [snackBar, setSnackBar] = useState<ToastProps | undefined>(undefined);

  const showToast = (
    newMessage: FC | string,
    newSeverity: 'success' | 'error' | 'warning' | 'info',
    options?: ShowToastOptions,
  ) => {
    const {
      anchorOrigin = { horizontal: 'right', vertical: 'top' },
      ...restOptions
    } = options ?? {};

    setSnackBar({
      message: newMessage,
      severity: newSeverity,
      anchorOrigin,
      ...restOptions,
    });
  };

  const handleClose = () => {
    setSnackBar(undefined);
  };

  return (
    <Suspense fallback={null}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <ToastContext.Provider value={{ showToast }}>
        {children}

        {!!snackBar && (
          <CustomToast
            type={snackBar?.severity as 'info'}
            open={!!snackBar}
            text={snackBar?.message || ''}
            containerId={snackBar?.containerId}
            anchorOrigin={snackBar?.anchorOrigin}
            header={snackBar?.header}
            sx={{ width: snackBar?.width, maxWidth: snackBar?.width }}
            onClose={handleClose}
            autoHideDuration={10000}
            verticalCenter={snackBar?.verticalCenter}
          />
        )}
      </ToastContext.Provider>
    </Suspense>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default ToastProvider;
