import { useMemo } from 'react';
import { Edge, Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import { ICanvasNodesResponse } from '@store/services/nodes/types';
import {
  formatEdgesForCanvas,
  formatNodeDTOToCanvasNode,
} from '@utils/helpers';
import { Subject } from 'rxjs';

export const saveEntitiesSubject = new Subject<{
  nodes: Node[];
  edges: Edge[];
  oldNodes: Node[];
}>();

const useSaveEntitiesResponse = () => {
  const { setNodes, setEdges, nodes } = useStore();

  const oldCanvasNodes = useMemo(
    () => nodes.filter(({ id }) => !id.startsWith('local')),
    [nodes],
  );

  return ({ nodes, relations }: ICanvasNodesResponse) => {
    const canvasNodes = nodes.map(formatNodeDTOToCanvasNode);

    const newCanvasNodes = [
      ...oldCanvasNodes.filter(({ type }) =>
        [NodesTypes.LayerNode, NodesTypes.SubLayerNode].includes(
          type as NodesTypes,
        ),
      ),

      ...canvasNodes,
    ];

    const newCanvasRelations = formatEdgesForCanvas(relations);

    setNodes(newCanvasNodes);
    setEdges(newCanvasRelations);

    saveEntitiesSubject.next({
      nodes: newCanvasNodes,
      edges: newCanvasRelations,
      oldNodes: oldCanvasNodes,
    });

    return { nodes: newCanvasNodes, relations: newCanvasRelations };
  };
};

export default useSaveEntitiesResponse;
