import React, { FC, memo, useEffect, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import DrawersOutlet from '@components/EntityDrawers/DrawersOutlet';
import { MainStage } from '@components/MainStage';
import useStore from '@components/MainStage/store';
import { SIDE_MENU_WIDTH } from '@components/SideMenu/SideMenu';
import { INITIAL_VIEWPORT } from '@constants/canvas/general';
import { Modes } from '@constants/canvas/layers';
import { LoadingKeys } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useResetViewport from '@hooks/canvas/useResetViewport';
import useAssets from '@hooks/useAssets';
import useLoading from '@hooks/useLoading';
import useProjectId from '@hooks/useProjectId';
import useToggle from '@hooks/useToggle';
import { Box, Stack } from '@mui/material';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { formatEdgesForCanvas, formatNodesForCanvas } from '@utils/helpers';
import { Stage } from '@views/Insights/Stage';
import ControlsBar from '@views/Project/components/ControlsBar';
import { ListMenu } from '@views/Project/components/ListMenu';
import { Versions } from '@views/Project/components/Versions';
import useAddAsset from '@views/Project/hooks/useAddAsset';
import useCurrentAssetInitialize from '@views/Project/hooks/useCurrentAssetInitialize';
import useFetchNodes from '@views/Project/hooks/useFetchNodes';

export const Project: FC = memo(() => {
  const { beginLoading } = useLoading();
  const [isListMenuOpen, { toggle: toggleListMenu }] = useToggle(true);
  const id = useProjectId(true);
  const {
    activeVersionId,
    setActiveVersionId,
    isVersionsShown,
    setIsVersionsShown,
    toolbox,
  } = useProject();
  const { mode } = toolbox;
  const { setViewport } = useReactFlow();
  const { zoom } = useCanvasZoom();

  const { setNodes, setEdges } = useStore();
  const { isNewAssetMode } = useAssets();
  const addAsset = useAddAsset();
  const resetViewPort = useResetViewport();

  useCurrentAssetInitialize();

  const { data } = useFetchNodes();

  const canvasNodes = useMemo(() => {
    return mode ? formatNodesForCanvas(data?.nodes, mode) : [];
  }, [activeVersionId, data, mode]);

  const canvasEdges = useMemo(() => {
    return formatEdgesForCanvas(data?.relations);
  }, [activeVersionId, data]);

  useEffect(() => {
    setIsVersionsShown(false);
    setActiveVersionId('');
  }, [id]);

  useEffect(() => {
    setViewport(INITIAL_VIEWPORT(zoom));

    const endLoading = beginLoading(LoadingKeys.RecalculateLayersHeight);

    setNodes(canvasNodes);
    setEdges(canvasEdges);

    if (isNewAssetMode) {
      addAsset({ canvasNodes });
    }

    setTimeout(() => {
      if (mode) {
        CanvasModelRenderer.redrawFullCanvasLayers(mode);
      }

      endLoading();
    }, 0);
  }, [canvasNodes, canvasEdges, isVersionsShown, mode]);

  const onToggleListMenu = () => {
    toggleListMenu();
    resetViewPort(isListMenuOpen ? SIDE_MENU_WIDTH : -SIDE_MENU_WIDTH);
  };

  const isLeftMenuVisible = !isVersionsShown || mode === Modes.RiskManagement;

  return (
    <Stack sx={{ height: 'calc(100vh - 180px)' }}>
      <Stack direction="row" flex={1} position="relative" overflow="hidden">
        {isLeftMenuVisible && <ListMenu open={isListMenuOpen} />}

        <Stack id="board-wrapper" flex={1} position="relative">
          <Box flex={1}>{isVersionsShown ? <Stage /> : <MainStage />}</Box>

          {isLeftMenuVisible && (
            <ControlsBar
              isListMenuOpen={isListMenuOpen}
              onToggleListMenu={onToggleListMenu}
            />
          )}
        </Stack>

        {isVersionsShown && <Versions />}

        <DrawersOutlet />
      </Stack>
    </Stack>
  );
});
