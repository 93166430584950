import React from 'react';
import { NodeProps } from 'reactflow';
import useCanvasWidth from '@hooks/canvas/useCanvasWidth';
import { Typography } from '@mui/material';
import { SubLayerIcon } from '@utils/iconsDefs';

import { SubLayerName, SubLayerNodeStyled } from './SubLayerNode.styled';

export type NodeData = {
  label: string;
  withBottomBorder?: boolean;
};

export function SubLayerNode({ id, data }: NodeProps<NodeData>) {
  const canvasWidth = useCanvasWidth();

  return (
    <SubLayerNodeStyled
      $withBottomBorder={!!data.withBottomBorder}
      id={id}
      $canvasWidth={canvasWidth}
    >
      <SubLayerName>
        <SubLayerIcon />
        <Typography variant="body1" color="grey.900" sx={{ ml: '10px' }}>
          {data.label}
        </Typography>
      </SubLayerName>
    </SubLayerNodeStyled>
  );
}
