import { CANVAS_LEFT_MARGIN, CANVAS_WIDTH } from '@constants/canvas/general';
import { themePalette } from '@theme/muiTheme';
import SublayerView from '@utils/canvas/SublayerView';
import styled from 'styled-components';

type SubLayerNodeProps = {
  $withBottomBorder: boolean;
  $canvasWidth: number;
};

export const SubLayerNodeStyled = styled('div')<SubLayerNodeProps>`
  position: relative;
  left: -${CANVAS_LEFT_MARGIN}px;
  width: ${({ $canvasWidth }) =>
    Math.max($canvasWidth, CANVAS_WIDTH + CANVAS_LEFT_MARGIN)}px;
  height: ${SublayerView.InitialHeight}px;
  display: flex;
  padding-left: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border-bottom: ${(p) =>
    p.$withBottomBorder && `2px dashed ${themePalette.grey[600]}`};
`;

export const SubLayerName = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 0;
`;
