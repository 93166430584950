import { LAYER_HEIGHT } from '@constants/canvas/general';
import {
  LayerSubLayers,
  LayerTypes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import LayerViewBase from '@utils/canvas/LayerViewBase';
import SublayerView from '@utils/canvas/SublayerView';

class LayerView extends LayerViewBase<LayerTypes> {
  static readonly TopTitleBlockHeight: number = LAYER_HEIGHT;

  setHeight(newHeight: number) {
    super.setHeight(newHeight + LayerView.TopTitleBlockHeight);
  }

  get subLayerIds(): SubLayerTypes[] {
    return LayerSubLayers[this.id];
  }

  get subLayersHeight(): number {
    return this.subLayerIds.reduce<number>((commonHeight, subLayerId) => {
      const subLayerController = new SublayerView(subLayerId);

      return commonHeight + subLayerController.getElementHeight();
    }, 0);
  }

  fitSubLayers(): void {
    this.setHeight(this.subLayersHeight);
  }
}

export default LayerView;
