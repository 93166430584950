import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useAssets from '@hooks/useAssets';
import useProjectData from '@hooks/useProjectData';
import useProjectId from '@hooks/useProjectId';
import { ROUTES } from '@router/routes';
import { Tabs } from '@views/Insights/types';
import { modeOptions } from '@views/Project/constants/toolbox';

const useModesDropdown = () => {
  const [{ subscription }] = useProjectData(undefined, true);
  const { saveCurrentZoom } = useCanvasZoom();
  const navigate = useNavigate();
  const projectId = useProjectId();

  const {
    toolbox: { mode },
    handleModeChange,
    handleCloseDrawer,
    handleDomainsChange,
    insightsTab,
    isInsightsMode,
    isVersionsShown,
    isRiskRegisterMode,
  } = useProject();

  const { resetAsset } = useAssets();

  const { handleClearAllFilters } = useProjectFilters();

  const handleModeSelect = async (m: Modes) => {
    await handleCloseDrawer();

    saveCurrentZoom();

    if (m === Modes.RiskManagement) {
      handleDomainsChange([]);
      handleClearAllFilters();

      // There is no insights for Risk Management
      if (isInsightsMode) {
        navigate(ROUTES.PROJECT.MODEL.replace(':id', projectId));
      }
    } else if (isRiskRegisterMode) {
      navigate(ROUTES.PROJECT.MODEL.replace(':id', projectId));
    }

    resetAsset();
    handleModeChange(m);
  };

  const isOnlyOneModeAvailable = subscription?.modes.length === 1;
  const isModeSelectionDisabled =
    isVersionsShown || (insightsTab !== Tabs.Default && isInsightsMode);

  const options = useMemo(() => {
    if (!subscription) return [];

    return modeOptions.filter(({ value }) =>
      subscription.modes.includes(value),
    );
  }, [subscription]);

  return {
    mode,
    handleModeSelect,
    isReadOnly: isModeSelectionDisabled || isOnlyOneModeAvailable,
    options,
  };
};

export default useModesDropdown;
