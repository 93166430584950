import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';

class LayerViewBase<IdType extends string> {
  protected readonly id: IdType;

  readonly element: HTMLElement;

  readonly node: Node;

  constructor(id: IdType) {
    const element = document.getElementById(id);

    if (!element) {
      throw new Error(`Element with id ${id} not found`);
    }

    this.id = id;
    this.element = element;
    this.node = useStore.getState().nodes.find((node) => node.id === id)!;
  }

  setHeight(newHeight: number): void {
    this.element.style.height = `${newHeight}px`;
  }

  getElementHeight(): number {
    return this.element.clientHeight;
  }

  setYPosition(y: number): void {
    useStore.getState().updateNodePosition(this.id, { y });
  }

  shiftYPosition(offset: number): void {
    if (offset !== 0) {
      this.setYPosition(this.node.position.y + offset);
    }
  }
}

export default LayerViewBase;
