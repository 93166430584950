import { CANVAS_LEFT_MARGIN, CANVAS_WIDTH } from '@constants/canvas/general';
import { Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const LayerNodeStyled = styled('div')<{
  $small?: boolean;
  $canvasWidth: number;
}>`
  position: relative;
  left: -${CANVAS_LEFT_MARGIN}px;
  width: ${({ $canvasWidth }) =>
    Math.max($canvasWidth, CANVAS_WIDTH + CANVAS_LEFT_MARGIN)}px;
  height: ${(p) => (p.$small ? '168px' : '264px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0 0 24px;
  background: transparent;
  border-bottom: 1px solid ${themePalette.grey[600]};
`;

export const StyledText = styled(Typography)`
  color: ${themePalette.grey[1000]};
`;
