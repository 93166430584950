import React, { forwardRef, memo } from 'react';
import { ContextMenu } from '@components/MainStage/ContextMenu/ContextMenu';
import { AddExistingNodesModal } from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/AddExistingNodesModal';
import useStore from '@components/MainStage/store';
import { ContextMenuProps } from '@components/MainStage/types';
import {
  BusinessAlignmentSubLayer,
  InitiativesSubLayerTypes,
  LayerTypes,
  Modes,
  PhysicalSubLayerTypes,
  subLayerParent,
  SubLayerTypes,
} from '@constants/canvas/layers';
import {
  AddNodeDrawerMap,
  ProjectDrawerTypes,
} from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useCenterNodeViewport from '@hooks/canvas/useCenterNodeViewport';
import useAssets from '@hooks/useAssets';
import useCanEditModel from '@hooks/useCanEditModel';
import useOutsideClick from '@hooks/useOutsideClick';
import useToggle from '@hooks/useToggle';
import { themePalette } from '@theme/muiTheme';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import {
  calculatePositionForNewNode,
  isAddExistingDisabled,
} from '@utils/canvasHelpers';
import { PlusIcon } from '@utils/iconsDefs';
import useAddAsset from '@views/Project/hooks/useAddAsset';

const SubLayerContextMenu = forwardRef<any, ContextMenuProps>(
  ({ isOpen, onClose, menu }, menuRef) => {
    const { nodes, addChildNode, getActiveNode, unselectActiveNode } =
      useStore();
    const {
      setDrawer,
      handleCloseDrawer,
      toolbox: { mode },
    } = useProject();

    const [isAddNewNodesOpen, addNewNodesControls] = useToggle<null | string>();
    const canEditModel = useCanEditModel();
    const centerNode = useCenterNodeViewport();

    useOutsideClick(menuRef as any, onClose);

    const { setNewAssetMode, selectedAssetId } = useAssets();
    const addAsset = useAddAsset();

    if (!menu) return null;

    const defaultMenuItems = [
      ...(canEditModel
        ? [
            {
              icon: <PlusIcon fill={themePalette.grey[800]} />,
              text: 'Add new entity',
              textColor: 'grey.1000',
              onClick: async () => {
                const activeNode = getActiveNode();
                const subLayerId = menu.id as BusinessAlignmentSubLayer;
                const layerId = subLayerParent[subLayerId];

                if (layerId === LayerTypes.AssessmentScope) {
                  await handleCloseDrawer();
                  setNewAssetMode();
                  setDrawer({
                    type: ProjectDrawerTypes.AddAssessmentScopeEntity,
                  });

                  if (!selectedAssetId) {
                    addAsset({ canvasNodes: nodes });
                  }

                  return;
                }

                const isDoubleAdding =
                  activeNode?.id.startsWith('local') &&
                  activeNode.parentNode === subLayerId;

                onClose();
                if (isDoubleAdding) return;

                unselectActiveNode();

                const position = calculatePositionForNewNode(subLayerId, nodes);

                addChildNode(menu.id, position, true);

                CanvasModelRenderer.redrawAfterSubLayerChanges(
                  mode!,
                  subLayerId,
                );

                setDrawer({ type: AddNodeDrawerMap[layerId] });
                centerNode(position, subLayerId);
              },
            },
          ]
        : []),
    ];

    const riskManagementMenuItems = [
      ...(canEditModel
        ? [
            {
              icon: <PlusIcon fill={themePalette.grey[800]} />,
              text: 'Add existing entities',
              textColor: 'grey.1000',
              disabled: isAddExistingDisabled({
                subLayerId: menu.id as SubLayerTypes,
                nodes,
              }),
              onClick: async () => {
                onClose();
                addNewNodesControls.on();
              },
            },
          ]
        : []),
    ];

    let menuItems = [...defaultMenuItems];

    if (mode === Modes.RiskManagement) {
      switch (menu.id) {
        case PhysicalSubLayerTypes.Capabilities:
        case PhysicalSubLayerTypes.Functions:
          menuItems = riskManagementMenuItems;
          break;
        case InitiativesSubLayerTypes.ChangeInitiatives:
          menuItems = [...defaultMenuItems, ...riskManagementMenuItems];
          break;
        default:
          break;
      }
    }

    if (menuItems.length === 0) return null;

    return (
      <>
        <AddExistingNodesModal
          assetId={selectedAssetId}
          layerId={menu.id}
          open={isAddNewNodesOpen}
          onClose={addNewNodesControls.off}
        />

        {isOpen && menu && menu.type === 'subLayer' && (
          <div ref={menuRef}>
            <ContextMenu menuItems={menuItems} {...menu} />
          </div>
        )}
      </>
    );
  },
);

export default memo(SubLayerContextMenu);
